<template>
    <div class="px-3 md:px-0 pt-4">

        <div class="header bg-blue-600 rounded-md px-4 py-3" v-if="kategori != null">
            <div class="flex items-center">
                <div class="text-white mr-2 cursor-pointer" @click="$router.go(-1)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>
                </div>
                <div>
                    <h4 class="text-gray-100 font-semibold tracking-wide text-sm">Daftar Materi</h4>
                    <p class=" font-semibold tracking-tight text-white sm:text-2xl">
                        {{ kategori.judul }}
                    </p>
                </div>
            </div>
        </div>

        <p class="my-4 text-xl leading-8 font-semibold tracking-tight text-gray-900 capitalize">
            Daftar Sub Kategori
        </p>
        
        <div class="animate-pulse" v-if="isLoading">
            <div class="flex bg-white py-2 px-3 my-2 transition-colors" v-for="i in 3" :key="i">
                <div class="h-16 w-16 bg-gray-100 rounded-lg content-center flex items-center text-center">
                </div>
                <div class="flex-grow ml-3 pt-1 pb-1">
                    <div class="h-6 bg-gray-100 rounded"></div>
                    <div class="h-5 w-32 mt-2 inline-block mr-2 bg-gray-100 rounded"></div>
                    <div class="h-5 w-40 mt-2 inline-block bg-gray-100 rounded"></div>
                </div>
            </div>
        </div>

        <div v-if="!isLoading">
            <router-link :to="{name: 'MateriSubKategoriList', params : {
                'singkatan_kategori' : kategori.singkatan.toLowerCase(),
                'singkatan_sub_kategori' : data.singkatan.toLowerCase(),
            }}" class="flex items-center bg-white py-2 px-3 shadow-md rounded-lg my-2 transition-colors hover:border-gray-300"
                v-for="(data, index) in kategori.sub_kategori" :key="index">
                <div class="h-16 w-16 bg-yellow-100 rounded-lg content-center flex items-center text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 mx-auto text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                    </svg>
                </div>
                <div class="flex-grow ml-3 pt-1 pb-1">
                    <h2 class="font-semibold tracking-tight text-gray-800 text-xl">{{ data.judul }}</h2>
                </div>
                <div class="w-10 h-10 bg-blue-100 rounded-full flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                </div>
            </router-link>
        </div>

   </div>
</template>
<script>
    import { errorHandler } from '../../helpers/Tools';
    export default {
        name: 'MateriSubKategori',
        data(){
            return {
                isLoading     : true,
                isLoadingPaket: true,
                kategori      : null,
            }
        },
        mounted() {
			this.getKategoriDetail();
		},
		methods : {
            async getKategoriDetail() {
                this.isLoading = true;
                try {
                    let res = await this.$store.dispatch("getKategoriDetail", this.$route.params.singkatan_kategori)
                    let result = res.data
                    this.isLoading = false;
                    if(result.status == 'success') {
                        this.kategori = result.data;
                    } 
                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },

        }
    }
</script>
